<template>    
  <main class="d-flex justify-content-center">
    <div class="p-3 p-sm-0" style="margin-top: 10vh;">
      <div class="card " style="border: 1px solid #e3e3e3; border-radius: 10px;">
        <div class="card-header">Please log in</div>
        <div class="card-body pe-5">
          <div class="row g-3  mb-3">
            <div class="col-4">
              <label for="inputUsername6" class="col-form-label">Username</label>
            </div>
            <div class="col-6">
              <input @keydown.enter="$refs.password.focus()" v-model="username" tabindex="1" type="text" id="inputUsername6" class="form-control" aria-describedby="usernameHelpInline">
            </div>
            <div class="col-2">
              <span id="usernameHelpInline" class="form-text clickable">
                Forgot?
              </span>
            </div>
          </div>

          <div class="row g-3 ">
            <div class="col-4">
              <label for="inputPassword6" class="col-form-label">Password</label>
            </div>
            <div class="col-6">
              <input ref="password" @keydown.enter="login" v-model="password" tabindex="2" type="password" id="inputPassword6" class="form-control" aria-describedby="passwordHelpInline">
            </div>
            <div class="col-2">
              <span id="usernameHelpInline" class="form-text clickable">
                Forgot?
              </span>
            </div>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-between">
          <a href="#" @click="$router.push('/register')" class="clickable me-auto">Register</a>
          <button tabindex="3" class="btn btn-success ms-auto" @click="login">Login</button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
import { getUser, postAuthLogin } from '../api'

export default {
  data() {
    return {
      username: "", 
      password: "",
    }
  },
  mounted() {
    this.$store.commit('UPDATE_SITE_CONFIG', { key: "isSidebarHidden", value: true })
  },
  components: {
    
  },
  computed: {
    ...mapGetters(['selectedAthlete', 'trackedAthletes', 'loggedIn', 'user'])
  },
  methods: {
    async login() {
      try {
        const { username, password } = this.$data;
        let { token } = (await postAuthLogin({ username, password })).data;
        console.log("Got token")
        await this.$store.dispatch('auth', token);
        this.$store.dispatch('getAthletes');
        await this.$store.dispatch('getUser', 'me');

        if (localStorage.getItem('last_path')) {
          this.$router.push(localStorage.getItem('last_path'))
        } else {
          this.$router.push('/')
        }
      } catch (e) {
        const message = e.response.data.error;
        this.Swal.fire({ title: "Uh-o", text: message || e.message, icon: 'error'})
      }
    }
  }
}
</script>

<style scoped>

.clickable {    
  margin: auto 0;
  font-size: 0.875em;
  color: #6c757d;
  white-space: nowrap;
  vertical-align: sub;
}

.clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>