<template>    
  <main class="flex-grow-1 align-content-center">
    TODO. For now please email shaun.barratt@shu.ac.uk for access.
  </main>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  mounted() {
    this.$store.commit('UPDATE_SITE_CONFIG', { key: "isSidebarHidden", value: true })
  },
  components: {
    
  },
  computed: {
    ...mapGetters(['selectedAthlete', 'trackedAthletes'])
  },
  methods: {
  }
}
</script>
