<template>    
  <main class="flex-grow-1 align-content-center">
    <div class="h-100">
      <div class="mt-2">
        <div>
            <div style="width: 0;" class="d-none d-lg-block">
                <h1 class="">Hello!</h1>
            </div>
            <h1 class="d-block d-lg-none">Hello</h1>
        </div>

        <div class="">
          <h4>Welcome to the prototype</h4>
          <p>Please report any bugs to: bugs@shaunbarratt.co.uk</p>
          <p class="text-muted">HINT: Select an athlete.</p>
        </div>

        <div class="mt-2">
          <h4>Coming soon™️:</h4>
          <ul>
            <li>Ability to track athletes relevant to you</li>
            <li>Ability to collect video data automatically</li>
            <li>Ability to annotate data automatically</li>
            <li>Ability to upload a video and retrieve back data about game changing events / collissions</li>
          </ul>
        </div>
    </div>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  mounted() {
    this.$store.commit('UPDATE_SITE_CONFIG', { key: "isSidebarHidden", value: false })
  },
  components: {
    
  },
  computed: {
    ...mapGetters(['selectedAthlete', 'trackedAthletes'])
  },
  methods: {
  }
}
</script>
